import { useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PhoneAlt from 'components/Icons/PhoneAlt';
import PinAlt from 'components/Icons/PinAlt';
import { CALL_CENTER_PHONE_NUMBER_BY_REGION } from 'constants/callCenter';
import useInterval from 'hooks/useInterval';
import { clearNonNumeric, dateToUTCFormat } from 'utils/common.utils';
import headerData from '../../globalscripts/jsons/headerData.json';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNewsletterModal } from 'redux/features/auth/authSlice';
import { RootState } from 'redux/store';
import { getWelcomeCampaing } from 'constants/welcomeCampaing';

export const PhoneNumberLink = () => {
  const router = useRouter();
  const phoneNumber = useMemo(
    () => CALL_CENTER_PHONE_NUMBER_BY_REGION[router.locale as string],
    [router.locale]
  );
  const phoneNumberHrefAttributeValue = useMemo(
    () => 'tel:' + clearNonNumeric(phoneNumber.replace(/\D/g, '')),
    [phoneNumber]
  );

  return (
    <a
      href={phoneNumberHrefAttributeValue}
      className="flex items-center text-white transition-colors duration-500 hover:text-brand-orange">
      <PhoneAlt fill="currentColor" className="mt-[3px]" />
      <span className="ml-[10px]">{phoneNumber}</span>
    </a>
  );
};



export const Announcement = () => {
  const [isShowing, setIsShowing] = useState(true);
  const [messageIndex, setMessageIndex] = useState(0);
  const router = useRouter();
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.authEvent.isLogin);
  const user = useSelector((state: RootState) => state.authEvent.user);
  const newsletterGuest = useSelector((state: RootState) => state.authEvent.newsletterGuest);

  const welcomeCampaign = useMemo(() => {
    return getWelcomeCampaing(router.locale!);
  }, [router]);

  const announcementMessages: any = useMemo(
    () => {
      const locale = router.locale ?? 'au';
      let headerMessages = (headerData as any)[locale]
        .sort((a: any, b: any) => {
          if (a.topHeaderMessageField.orderIndex > b.topHeaderMessageField.orderIndex) return 1;
          if (b.topHeaderMessageField.orderIndex > a.topHeaderMessageField.orderIndex) return -1;
          return 0;
        });

      if (locale === 'international') {
        headerMessages = headerMessages.filter(
          (header: any) => header.topHeaderMessageField.showInInternational == true
        )
      }

      const currentDate = new Date();

      headerMessages = headerMessages.filter((header: any) => {

        if (header.topHeaderMessageField.startDate == null && header.topHeaderMessageField.endDate == null) {
          return true;
        }

        if (dateToUTCFormat(currentDate) > new Date(header.topHeaderMessageField.startDate) && header.topHeaderMessageField.endDate == null) {
          return true;
        }

        if ((new Date(header.topHeaderMessageField.endDate) > dateToUTCFormat(currentDate)) && header.topHeaderMessageField.startDate == null) {
          return true;
        }

        if (!(dateToUTCFormat(currentDate) > new Date(header.topHeaderMessageField.startDate)) &&
          !(new Date(header.topHeaderMessageField.endDate) > dateToUTCFormat(currentDate))) {
          return false;
        }

        if (!(dateToUTCFormat(currentDate) > new Date(header.topHeaderMessageField.startDate)) ||
          !(new Date(header.topHeaderMessageField.endDate) > dateToUTCFormat(currentDate))) {
          return false;
        }

        return true;
      });

      // if ((isLogin && user.new_opt_in != 'ALL') || (isLogin && user.new_opt_in == 'ALL' && user.channel_email == false) || (!isLogin && !newsletterGuest)) {
      //custom Newsletter 
      // headerMessages.push({
      //   title: 'Subscribe to the newsletter and receive 5% off',
      //   topHeaderMessageField: {
      //     buttonText: 'Subscribe',
      //     destinationLink: '#',
      //     endDate: null,
      //     orderIndex: 99,
      //     showInInternational: true,
      //     startDate: null
      //   }
      // });
      // }

      if (!isLogin || (isLogin && (('order_count' in user && Number(user.order_count) == 0)))) {
        //custom Newsletter 
        headerMessages.push({
          title: 'Get ' + welcomeCampaign.discount + '% off on your FIRST order!',
          topHeaderMessageField: {
            buttonText: 'Get Code',
            destinationLink: '/' + welcomeCampaign.slug,
            endDate: null,
            orderIndex: 99,
            showInInternational: true,
            startDate: null
          }
        });
      }

      return headerMessages;
    }, [router.locale, newsletterGuest, user]
  )

  const message = useMemo(
    () =>
      announcementMessages[messageIndex]?.title ?? '',
    [announcementMessages, messageIndex]
  );

  const buttonText = useMemo(
    () =>
      announcementMessages[messageIndex]?.topHeaderMessageField.buttonText ?? '',
    [announcementMessages, messageIndex]
  )

  // Adding /au/ in front of the link
  const buttonPath = useMemo(
    () => {
      return announcementMessages[messageIndex]?.topHeaderMessageField.destinationLink ?? ''
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [announcementMessages, messageIndex, router.locale]
  );

  useInterval(() => {
    if (announcementMessages.length < 2) return setMessageIndex(0);

    const isExceed = messageIndex + 1 >= announcementMessages.length;
    setIsShowing(false);
    setTimeout(() => {
      setMessageIndex(isExceed ? 0 : messageIndex + 1);
      setIsShowing(true);
    }, 500);
  }, 5500);

  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-[500ms] transform"
      enterFrom="translate-x-[-40px] opacity-0"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-[450ms] transform"
      leaveFrom="translate-y-0"
      leaveTo="translate-x-[80px] opacity-0"
      className={'flex justify-center gap-2 shop:py-2 px-2 items-center'}>

      {(announcementMessages.length > 0 && buttonText !== 'Subscribe' && buttonText !== 'AlreadySubscribe') ? (
        <>
          <p className="text-center" dangerouslySetInnerHTML={{ __html: message }} />
          <Link
            prefetch={false}
            href={buttonPath}
            className="text-center pt-[1px] pb-[2px] cursor-pointer rounded border min-w-fit border-white px-[8px] text-xs font-medium text-white transition-colors duration-500 hover:bg-white hover:text-brand-black100">

            {buttonText}

          </Link>
        </>
      ) : (<></>)}

      {(announcementMessages.length > 0 && buttonText === 'Subscribe') ? (
        <>
          <p className="text-center cursor-pointer" onClick={() => {
            dispatch(setIsNewsletterModal(true));
          }}>{message}</p>
          <a onClick={() => {
            dispatch(setIsNewsletterModal(true));
          }}
            className="text-center pt-[1px] pb-[2px] cursor-pointer rounded border min-w-fit border-white px-[8px] text-xs font-medium text-white transition-colors duration-500 hover:bg-white hover:text-brand-black100">
            {buttonText}
          </a>
        </>
      ) : (<></>)}

      {(announcementMessages.length > 0 && buttonText === 'AlreadySubscribe') ? (
        <>
          <p className="text-center cursor-pointer">{message}</p>
        </>
      ) : (<></>)}



    </Transition>
  );
};

export const FindAStore = () => (
  (<Link
    prefetch={false}
    href="/stores"
    className="flex items-center text-white transition-colors duration-500 hover:text-brand-orange">

    <PinAlt fill="currentColor" width="11" height="15" className="mt-[2px]" />
    <span className="ml-[10px] text-[14px] font-medium">Find a Store</span>

  </Link>)
);

const DefaultHeaderAnnouncementBanner = () => {
  const router = useRouter();
  return (
    <div className="bg-brand-black100 py-1 text-[14px] text-white">
      <div className="mx-auto flex max-w-[1224px] items-center justify-between px-4 min-h-[38px]">
        <FindAStore />
        <Announcement key={router.locale} />
        <PhoneNumberLink />
      </div>
    </div>
  );
};

const MobileHeaderAnnouncementBanner = () => {
  const router = useRouter();
  return (
    <div className="bg-brand-black100 text-[10px] font-medium text-white md:text-[12px] flex justify-center min-h-[42px]">
      <Announcement key={router.locale} />
    </div>
  );
};

export { MobileHeaderAnnouncementBanner, DefaultHeaderAnnouncementBanner };
