import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import useScrollDirection from "hooks/useScrollDirection";
import { useAppSelector } from "redux/hooks";
import { CATEGORY_LIST_BY_LOCALE } from "../HeaderNavigationCategories";
import { RegionKey } from "../HeaderNavigationRegionSelect";
import menuImageStore from "zus/menuImageStore";

/* eslint-disable no-unused-vars */
interface HeaderHelpersOptions {
    showAuthModal: (type: 'SIGN_IN' | 'SIGN_UP', path?: string) => void;
}
export enum UserMenuAction {
  signIn = 'SIGN_IN',
  signUp = 'SIGN_UP',
  myOrders = 'MY_ORDERS',
  myPrescriptions = 'MY_PRESCRIPTIONS',
  myProfile = 'MY_PROFILE',
  addresses = 'ADDRESSES',
  referral = 'REFERRAL',
  logout = 'LOGOUT',
  trustedDevices = 'TRUSTED_DEVICES',
  reOrderContactLenses = 'RE_ORDER_CONTACT_LENSES'
}
export type MenuActionClickHandler = (action: UserMenuAction) => void;
export interface HeaderNavigationCategoryItems {
  name: string;
  path: string;
}

export interface HeaderNavigationCategorySections{
  name: string;
  items?: HeaderNavigationCategoryItems[]
}
export interface HeaderNavigationCategory {
  name: string;
  items?: HeaderNavigationCategoryItems[];
  bannerImage?: string;
  sections?: any[];
}

export interface HeaderLoggedUser {
  firstName: string;
  email: string;
}
/* eslint-enable no-unused-vars */


const useHeaderHelpers = ({showAuthModal}:HeaderHelpersOptions) => {
  const user = useAppSelector((state) => state.authEvent.user);
  const router = useRouter();

  const categoryList = useMemo(
    () => CATEGORY_LIST_BY_LOCALE[router.locale as RegionKey],
    [router.locale]
  );



  const menuActionClickHandler: MenuActionClickHandler = (action) => {
    switch (action) {
      case UserMenuAction.signIn:
        return showAuthModal('SIGN_IN');
      case UserMenuAction.signUp:
        return showAuthModal('SIGN_UP');
      default:
        return navigateToMenuActionPath(action);
    }
  };

  const MENU_ACTION_PATHS: Record<string, string> = {
    [UserMenuAction.myOrders]: '/orders',
    [UserMenuAction.myPrescriptions]: '/prescriptions',
    [UserMenuAction.myProfile]: '/profile',
    [UserMenuAction.logout]: '/logout',
    [UserMenuAction.addresses]: '/addresses',
    [UserMenuAction.trustedDevices]: '/trusted-devices',
    [UserMenuAction.referral]: '/referral',
    [UserMenuAction.reOrderContactLenses]: '/re-order-contact-lenses'
  };

  const navigateToMenuActionPath = (action: UserMenuAction) => {
    if (!loggedUser) {
      showAuthModal('SIGN_IN', MENU_ACTION_PATHS[action] || '');
      return;
    }

    router.push(MENU_ACTION_PATHS[action]);
  };

  const loggedUser: HeaderLoggedUser | undefined = useMemo(() => {
    if (!user?.email) return;
    const [firstName] = user.display_name?.trim?.()?.split?.(' ');
    return {
      firstName: firstName as string || user.email,
      email: user.email as string
    };
  }, [user]);

  const [scrollDirection, scrollPosY] = useScrollDirection({
    initialDirection: 'DOWN',
    thresholdPixels: 140
  });

  return {scrollDirection, scrollPosY, categoryList, menuActionClickHandler, loggedUser}
}


export default useHeaderHelpers