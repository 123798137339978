import { FC, useMemo, useState } from 'react';
import cx from 'classnames';
import Image from "next/legacy/image";
import { useRouter } from 'next/router';
import Dropdown from 'components/Dropdown/Dropdown';
import { UpdateRegion } from './HeaderNavigation';

export type RegionKey = 'international' | 'ca' | 'au' | 'nz';

interface HeaderNavigationRegionSelectProps {
  updateRegion: UpdateRegion;
}

interface Region {
  name: string;
  currency: string;
  flag: {
    rounded: string;
    default: string;
  };
}

const AVAILABLE_REGIONS: Record<RegionKey, Region> = {
  au: {
    name: 'Australia',
    currency: 'AUD',
    flag: {
      rounded: '/images/regions/rounded/au.svg',
      default: '/images/regions/default/au.jpg'
    }
  },
  ca: {
    name: 'Canada',
    currency: 'CAD',
    flag: {
      rounded: '/images/regions/rounded/ca.svg',
      default: '/images/regions/default/ca.jpg'
    }
  },
  nz: {
    name: 'New Zealand',
    currency: 'NZD',
    flag: {
      rounded: '/images/regions/rounded/nz.svg',
      default: '/images/regions/default/nz.jpg'
    }
  },
  international: {
    name: 'International',
    currency: 'AUD',
    flag: {
      rounded: '/images/regions/rounded/int.svg',
      default: '/images/regions/default/int.jpg'
    }
  }
};

const HeaderNavigationRegionSelect: FC<HeaderNavigationRegionSelectProps> = ({
  updateRegion
}) => {
  const router = useRouter();
  const selectedRegion = useMemo(
    () => AVAILABLE_REGIONS[router.locale as RegionKey],
    [router.locale]
  );

  const [isActive, setIsActive] = useState(false);

  return (
    <Dropdown
      onToggle={setIsActive}
      title="Select a Region"
      className={cx(
        'flex h-full items-center border-b-[3px] transition-colors duration-500',
        {
          'border-transparent': !isActive,
          'border-brand-orange': isActive
        }
      )}
      contentWrapperClassName="w-[227px] translate-x-[-64%] shadow-navbarOverlay max-h-auto !rounded-none !mt-[3px] !py-[21px] !px-0"
      contentWrapperParentClassName="!max-h-none "
      interactionType="MOUSEOVER"
      content={
        <HeaderNavigationRegionSelectMenu updateRegion={updateRegion} />
      }>
      <div className="flex h-[36px] w-[81px] cursor-pointer items-center rounded-[20px] bg-brand-blue bg-opacity-20 px-[8px]">
        <Image
          alt={selectedRegion.name + ' flag'}
          width={22}
          height={22}
          src={selectedRegion.flag.rounded}
        />
        <span className="ml-[9px] mr-[3px] text-brand-black100">
          <span className="text-[14px] leading-[26px]">
            {selectedRegion.currency}
          </span>
        </span>
      </div>
    </Dropdown>
  );
};

const HeaderNavigationRegionSelectMenu = ({
  updateRegion,
  onToggle
}: {
  updateRegion: UpdateRegion;
  onToggle?: () => void;
}) => {
  const router = useRouter();
  const sortedRegionList = useMemo(
    () =>
      Object.keys(AVAILABLE_REGIONS)
        .map((regionKey) => ({
          key: regionKey,
          ...AVAILABLE_REGIONS[regionKey as RegionKey]
        }))
        .sort((prev) => (prev.key.toLowerCase() === router.locale ? -1 : 1)),
    [router.locale]
  );

  const onUpdateRegion = (region: RegionKey) => {
    if (onToggle) onToggle();
    updateRegion(region);
  };
  return (
    <div className="flex flex-col lg:px-[20px]">
      {sortedRegionList.map((region) => (
        <div
          onClick={() => onUpdateRegion(region.key as RegionKey)}
          className="group mb-[18px] grid h-[25px] cursor-pointer grid-cols-[26px,1fr,auto] items-center gap-[15px] last:mb-0"
          key={region.key}>
            <span className="h-[22px] w-[32px] relative">
          <Image
            layout="fill"
            alt={region.name + ' flag'}
            src={region.flag.default}
            className="rounded-[2px]"
          /></span>
          <span className="text-[16px] leading-[20px] text-brand-black100 transition-colors duration-500 group-hover:text-brand-orange">
            {region.name}
          </span>
          <span className="text-[12px] leading-[16px] text-brand-grey300">
            {region.currency}
          </span>
        </div>
      ))}
    </div>
  );
};

export const MobileRegionSelect = ({
  updateRegion
}: {
  updateRegion: UpdateRegion;
}) => {
  const router = useRouter();
  const selectedRegion = useMemo(
    () => AVAILABLE_REGIONS[router.locale as RegionKey],
    [router.locale]
  );

  return (
    <Dropdown
      title="Select a region"
      disableScrollLockOnMobile={true}
      content={
        <HeaderNavigationRegionSelectMenu updateRegion={updateRegion} />
      }>
      <div className="relative flex items-center bg-brand-grey500 py-[14px] px-[16px]">
        <Image
          src={selectedRegion.flag.default}
          layout="intrinsic"
          alt={selectedRegion.name + ' flag'}
          width={26}
          height={18}
        />
        <span className="ml-[8px] h-full text-[14px] leading-[14px] text-brand-black100">
          {selectedRegion.name}
        </span>
      </div>
    </Dropdown>
  );
};

export default HeaderNavigationRegionSelect;
