import cx from 'classnames';
import Basket from 'components/Icons/Basket';
import Spinner from 'components/Spinner/Spinner';

interface HeaderNavigationBasketProps {
  ready?: boolean;
  itemCount?: number | string | undefined;
  onBasketClick?: () => void;
  className?: string;
}

const HeaderNavigationBasket = ({
  ready = true,
  itemCount,
  onBasketClick,
  className = ''
}: HeaderNavigationBasketProps) => {
  return (
    <div
      onClick={onBasketClick}
      className={cx(
        'relative flex h-full cursor-pointer items-center text-brand-black100 transition-all duration-500 hover:text-brand-orange',
        className
      )}>
      <span className="relative">
        <HeaderNavigationBasketBadge ready={ready} itemCount={itemCount} />
        <Basket fill="currentColor" />
      </span>
    </div>
  );
};

const HeaderNavigationBasketBadge = ({
  ready,
  itemCount
}: HeaderNavigationBasketProps) =>
  !ready ? (
    <div className="absolute top-[-12px] left-[14px]">
      <Spinner fill="#00AEC7" />
    </div>
  ) : (
    <>
      {!!itemCount && (
        <div className="absolute top-[-12px] left-[11px] flex h-[20px] w-[20px] items-center justify-center rounded-full border border-white bg-brand-orange text-center font-secondary text-[10px] font-medium leading-[20px] text-white">
          {itemCount}
        </div>
      )}
    </>
  );

export default HeaderNavigationBasket;
