import { SVGProps } from 'react';

const AccountAlt = ({
  width = 22,
  height = 22,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6862 17.7499C21.1363 15.8865 22 13.5441 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C13.9811 22 16.6852 20.8141 18.6664 18.8884C18.6664 18.8884 18.6664 18.8884 18.6664 18.8884C19.0319 18.5332 19.3727 18.1528 19.6862 17.75C19.6862 17.75 19.6862 17.75 19.6862 17.7499ZM18.6664 16.6115C19.8192 15.0392 20.5 13.0991 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 13.0991 2.18082 15.0393 3.33371 16.6116C5.31486 14.6859 8.01898 13.5 11.0001 13.5C13.9811 13.5 16.6852 14.6858 18.6664 16.6115ZM17.6849 17.75C15.9684 16.0499 13.6068 15 11.0001 15C8.39327 15 6.03169 16.05 4.31517 17.75C6.03169 19.4501 8.39323 20.5 11 20.5C13.6068 20.5 15.9684 19.45 17.6849 17.75ZM11 12C13.2091 12 15 10.2091 15 8C15 5.79086 13.2091 4 11 4C8.79086 4 7 5.79086 7 8C7 10.2091 8.79086 12 11 12ZM11 10.5C12.3807 10.5 13.5 9.38071 13.5 8C13.5 6.61929 12.3807 5.5 11 5.5C9.61929 5.5 8.5 6.61929 8.5 8C8.5 9.38071 9.61929 10.5 11 10.5Z"
      fill={fill}
    />
  </svg>
);

export default AccountAlt;
