import { SVGProps } from 'react';

const PinAlt = ({
  width = 22,
  height = 30,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 30"
    {...otherProps}>
    <path
      d="M11 7.713c-1.87 0-3.385 1.535-3.385 3.429 0 1.893 1.515 3.428 3.385 3.428 1.87 0 3.384-1.535 3.384-3.428 0-1.894-1.514-3.43-3.384-3.43Z"
      fill={fill}
    />
    <path
      d="M11 30a.945.945 0 0 1-.733-.352C9.842 29.138 0 17.076 0 11.162 0 5.012 4.94 0 11 0s11 5.013 11 11.162c0 5.914-9.842 17.976-10.267 18.486A.945.945 0 0 1 11 30Zm0-28.042c-5.018 0-9.07 4.152-9.07 9.204 0 3.995 5.982 12.376 9.07 16.292 3.088-3.916 9.07-12.337 9.07-16.292 0-5.053-4.053-9.204-9.07-9.204Z"
      fill={fill}
    />
  </svg>
);

export default PinAlt;
