import { SVGProps } from 'react';

const Basket = ({
  width = 22,
  height = 22,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...otherProps}>
    <path
      d="M9.43291 0C7.46863 0 5.8972 1.41036 5.8972 3.14286V5.5H2.36149C2.16664 5.49892 1.97834 5.57028 1.83315 5.70023C1.68796 5.83018 1.59623 6.00944 1.57577 6.20321L0.00434459 21.1318C-0.0072604 21.2417 0.00444715 21.3528 0.0387026 21.4578C0.072958 21.5629 0.128991 21.6596 0.203141 21.7415C0.277292 21.8234 0.367893 21.8888 0.469027 21.9334C0.57016 21.9779 0.679553 22.0006 0.790058 22H18.0758C18.1863 22.0006 18.2957 21.9779 18.3968 21.9334C18.4979 21.8888 18.5885 21.8234 18.6627 21.7415C18.7368 21.6596 18.7929 21.5629 18.8271 21.4578C18.8614 21.3528 18.8731 21.2417 18.8615 21.1318L17.29 6.20321C17.2696 6.00944 17.1779 5.83018 17.0327 5.70023C16.8875 5.57028 16.6992 5.49892 16.5043 5.5H12.9686V3.14286C12.9686 1.41036 11.3972 0 9.43291 0ZM7.46863 3.14286C7.46863 2.27464 8.34863 1.57143 9.43291 1.57143C10.5172 1.57143 11.3972 2.27464 11.3972 3.14286V5.5H7.46863V3.14286ZM15.7972 7.07143L17.2036 20.4286H1.6622L3.06863 7.07143H15.7972Z"
      fill={fill}
    />
  </svg>
);

export default Basket;
