import { FC, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const BOOKING_BUTTONS_BY_LOCALE: Record<
  string,
  { text: string; path: string; external: boolean }
> = {
  au: {
    text: 'Book an optometrist',
    path: '/au/book-an-optometrist',
    external: true
  },
  ca: {
    text: 'Book an eye exam',
    path: '/ca/book-an-eye-exam',
    external: true
  }
};

const HeaderBookingButton: FC<{ className?: string }> = ({
  className = ''
}) => {
  const router = useRouter();

  const bookingLinkAttributes = useMemo(
    () => BOOKING_BUTTONS_BY_LOCALE[router.locale as string],
    [router.locale]
  );

  return (
    !!bookingLinkAttributes &&
    (!bookingLinkAttributes.external ? (
      <HeaderBookingButtonLink
        path={bookingLinkAttributes.path}
        text={bookingLinkAttributes.text}
        className={className}
      />
    ) : (
      <HeaderBookingButtonExternalLink
        text={bookingLinkAttributes.text}
        path={bookingLinkAttributes.path}
        className={className}
      />
    ))
  );
};

const HeaderBookingButtonLink = ({
  path = '',
  text,
  className
}: {
  path?: string;
  text: string;
  className: string;
}) => (
  (<Link
    prefetch={false}
    href={path}
    className={`font-medium text-brand-blue transition-colors hover:text-brand-orange ${className}`}
    data-ignore-category-overlay>

    {text}

  </Link>)
);

const HeaderBookingButtonExternalLink = ({
  path = '',
  text,
  className
}: {
  path?: string;
  text: string;
  className: string;
}) => (
  <a
    href={path}
    className={`font-medium text-brand-blue transition-colors hover:text-brand-orange ${className}`}
    data-ignore-category-overlay>
    {text}
  </a>
);

export default HeaderBookingButton;
